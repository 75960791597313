export enum FilterVariableTypeEnum {
  BOOLEAN = "boolean",
  FUNCTION = "function",
  NUMBER = "number",
  STRING = "string",
}

export enum FilterOperatorEnum {
  MORE_THAN = "MORE_THAN",
  LESS_THAN = "LESS_THAN",
  EQUAL = "EQUAL",
}

interface ApplicationFilter {
  name: string;
  readableName: string;
  variableNames: string[];
  variableTypes: (string | typeof FilterOperatorEnum)[];
}

export default ApplicationFilter;
