import { useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Input, InputNumber, Row, Select, Spin, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import idx from "idx";

import api from "../../../api/_.api";
import ApplicationFilter, {
  FilterVariableTypeEnum,
} from "../../../types/ApplicationFilter.type";
import ApplicationFunction from "../../../types/ApplicationFunction.type";

import styles from "../Application.module.css";

import { ApiApplicationFiltersResponseDTO } from "./ApplicationFilters.type";
import { ApiApplicationFunctionsResponseDTO } from "./ApplicationFunctions.type";

interface IFilterMap {
  [filterName: string]: ApplicationFilter;
}

const DISABLED_ACTIONS: string[] = [
  // "KITTIES_AUCTION_COUNT_AND_AVG_START_PRICE",
  // "USERS_SENT_THIS_FUNCTION",
  // "USERS_TRANSACTING_OPERATOR",
];

interface ISelectOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

interface IApplicationUserInteractions {
  applicationId: string;
}
function ApplicationFilters({ applicationId }: IApplicationUserInteractions) {
  const [appliedFilter, setAppliedFilter] = useState<string | null>(null);
  const [appliedFilterVariables, setAppliedFilterVariables] = useState<any[]>(
    []
  );
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [selectedFilterVariables, setSelectedFilterVariables] = useState<any[]>(
    []
  );

  // Request to get all the filters of an application
  const getFiltersUrl = api.applicationFilters.getFilters(applicationId);
  const {
    isLoading: isLoadingFilterData,
    error: errorFilterData,
    data: rawFilterData,
  } = useQuery<ApiApplicationFiltersResponseDTO, Error>({
    queryKey: ["applicaitonFilterData-" + applicationId, getFiltersUrl],
    queryFn: () => fetch(getFiltersUrl).then((res) => res.json()),
  });
  const filterList =
    idx(rawFilterData, (_) => _.specificApplicationFilters) || null;

  // Convert the list of filters to a map from
  const filterMap: IFilterMap = {};

  if (filterList) {
    filterList.forEach((currentFilter) => {
      filterMap[currentFilter.name] = currentFilter;
    });
  }

  // Request to get all the Functions of an application
  const getFunctionsUrl = api.applicationFunctions.getFunctions(applicationId);
  const {
    isLoading: isLoadingFunctionsData,
    error: errorFunctionsData,
    data: rawFunctionsData,
  } = useQuery<ApiApplicationFunctionsResponseDTO, Error>({
    queryKey: ["applicaitonFunctionsData-" + applicationId, getFunctionsUrl],
    queryFn: () => fetch(getFunctionsUrl).then((res) => res.json()),
  });
  const functionList = idx(rawFunctionsData, (_) => _.functionListing) || null;

  // const fullFunctionList =
  //   idx(rawFunctionsData, (_) => _.functionListingComplete) || null;

  const functionSelectList: ISelectOptions[] = [];

  if (functionList) {
    // Loop through the application addresses
    Object.keys(functionList).forEach((currentApplicationAddress: string) => {
      const currentApplication: ApplicationFunction[] =
        functionList[currentApplicationAddress];

      // Loop through the specific application functions for each address
      currentApplication.forEach(({ name, hash }: ApplicationFunction) => {
        functionSelectList.push({
          value: `${currentApplicationAddress}-${hash}`,
          label: name,
        });
      });
    });

    functionSelectList.sort((a: ISelectOptions, b: ISelectOptions) =>
      ("" + a.label).localeCompare(b.label)
    );
  }

  const applyFiltersUrl = api.applicationFilters.applyFilters(applicationId);
  const {
    data: appliedFiltersData,
    error: errorAppliedFiltersData,
    isLoading: isLoadingAppliedFiltersData,
    mutate: mutateAppliedFiltersData,
  } = useMutation(
    (newObjectPayload: any) => axios.post(applyFiltersUrl, newObjectPayload),
    {
      onSuccess: () => {
        // Invalidate and refetch
      },
    }
  );

  console.log("_+_+_+_+_");
  console.log(appliedFiltersData);
  console.log(errorAppliedFiltersData);
  console.log(isLoadingAppliedFiltersData);

  // useEffect(() => {
  //   if (
  //     !isLoadingFilterData &&
  //     !isLoadingFunctionsData &&
  //     !!rawFunctionsData &&
  //     !!rawFilterData
  //   ) {
  //     console.log("-=-=- filterList");
  //     console.log(filterList);
  //     console.log("*** *** *** *** *** filterMap");
  //     console.log(filterMap);
  //     console.log("*** *** *** *** *** functionSelectList");
  //     console.log(functionSelectList);
  //   }
  // }, [
  //   isLoadingFilterData,
  //   isLoadingFunctionsData,
  //   rawFunctionsData,
  //   rawFilterData,
  //   filterList,
  //   filterMap,
  //   functionSelectList,
  // ]);

  const handleChangeFilter = (value: string) => {
    // Set up an empty array as the placeholder values
    setSelectedFilterVariables(filterMap[value].variableNames.map(() => null));

    // Set the current filter
    setSelectedFilter(value);
  };

  const handleChangeFilterVariable = (filterVariableIndex: number) => {
    // Construct a new handler based on the index of the variable
    return (value: string | boolean | number | null) => {
      // Clone the existing data
      const updatedFilterVariableDataArray = [...selectedFilterVariables];

      // Update the index of the variable to change
      updatedFilterVariableDataArray[filterVariableIndex] = value;

      // Now update the entire data payload
      setSelectedFilterVariables(updatedFilterVariableDataArray);
    };
  };

  // Function that displays the params of the filter
  const renderFilterParamContent = () => {
    if (!!selectedFilter && selectedFilter in filterMap) {
      const currentFilter = filterMap[selectedFilter];

      // Go through each of the variables in the filter and put the
      return currentFilter.variableNames.map(
        (currentVariableName, filterVariableIndex) => {
          const currentVariableType: any =
            currentFilter.variableTypes[filterVariableIndex];

          if (currentVariableType === FilterVariableTypeEnum.BOOLEAN) {
          } else if (currentVariableType === FilterVariableTypeEnum.FUNCTION) {
            // Passed in that it is looking for one of the functions that have data
            return (
              <Select
                key={`filter_varible-${filterVariableIndex}`}
                showSearch
                placeholder={`Select ${currentVariableName}`}
                style={{ width: 200 }}
                onChange={handleChangeFilterVariable(filterVariableIndex)}
                options={functionSelectList}
                value={selectedFilterVariables[filterVariableIndex]}
              />
            );
          } else if (currentVariableType === FilterVariableTypeEnum.NUMBER) {
            return (
              <InputNumber
                key={`filter_varible-${filterVariableIndex}`}
                placeholder={currentVariableName}
                style={{ width: 200 }}
                onChange={handleChangeFilterVariable(filterVariableIndex)}
                value={selectedFilterVariables[filterVariableIndex]}
              />
            );
          } else if (currentVariableType === FilterVariableTypeEnum.STRING) {
            return (
              <Input
                key={`filter_varible-${filterVariableIndex}`}
                placeholder={currentVariableName}
                style={{ width: 200 }}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  handleChangeFilterVariable(filterVariableIndex)(
                    e.target.value
                  );
                }}
                value={selectedFilterVariables[filterVariableIndex]}
              />
            );
          } else if (
            typeof currentVariableType === "object" &&
            currentVariableType !== null &&
            !Array.isArray(currentVariableType)
          ) {
            // Passed in a enum so need to just display the options as select
            const enumSelectOptions: ISelectOptions[] = Object.keys(
              currentVariableType
            )
              .map((value) => ({
                label: currentVariableType[value],
                value,
              }))
              .sort((a: ISelectOptions, b: ISelectOptions) =>
                ("" + a.label).localeCompare(b.label)
              );
            return (
              <Select
                key={`filter_varible-${filterVariableIndex}`}
                showSearch
                placeholder={`Select ${currentVariableName}`}
                style={{ width: 200 }}
                onChange={handleChangeFilterVariable(filterVariableIndex)}
                options={enumSelectOptions}
                value={selectedFilterVariables[filterVariableIndex]}
              />
            );
          }

          return null;
        }
      );
    }
  };

  const handleApplyFilterClick = () => {
    setAppliedFilter(selectedFilter);
    setAppliedFilterVariables([...selectedFilterVariables]);

    console.log("<><><><><><> selectedFilter");
    console.log(selectedFilter);

    console.log("<><><><><><> selectedFilterVariables");
    console.log(selectedFilterVariables);

    mutateAppliedFiltersData({
      filters: [
        {
          name: selectedFilter,
          variables: selectedFilterVariables,
        },
      ],
    });
  };

  const handleClearFilterClick = () => {
    setAppliedFilter(null);
    setAppliedFilterVariables([]);

    setSelectedFilter(null);
    setSelectedFilterVariables([]);
  };

  const hasCompletedFilterVariables =
    !!selectedFilter &&
    selectedFilterVariables.reduce(
      (previousValue, currentValue) =>
        previousValue && currentValue !== undefined && currentValue !== null,
      true
    );

  const appliedFilterChanged =
    appliedFilter !== selectedFilter ||
    appliedFilterVariables.join("-") !== selectedFilterVariables.join("-");

  const renderFilterButton = () => {
    if (!!appliedFilter) {
      return (
        <Button
          type="link"
          onClick={handleApplyFilterClick}
          disabled={!hasCompletedFilterVariables || !appliedFilterChanged}
        >
          Update Filter
        </Button>
      );
    }
    return (
      <Button
        type={
          !(hasCompletedFilterVariables && !appliedFilter) ? "link" : "primary"
        }
        onClick={handleApplyFilterClick}
        disabled={!(hasCompletedFilterVariables && !appliedFilter)}
      >
        Apply Filter
      </Button>
    );
  };

  const renderTable = () => {
    interface DataType {
      key: string;
      name: string;
      age: number;
      address: string;
      tags: string[];
    }
    const filterResult =
      idx(appliedFiltersData, (_) => _.data.filterResult) || null;

    if (!isLoadingAppliedFiltersData && !!filterResult) {
      const { data: filterData, visuals } = filterResult;
      const {
        // representation,
        title: visualTitle,
        config: visualConfig,
      } = visuals[0];
      console.log("-=-=- appliedFiltersData");
      console.log(appliedFiltersData);

      const columns: ColumnsType<DataType> = visualConfig.columnHeaders.map(
        (columnHeaderName: string, columnIndex: number) => ({
          title: columnHeaderName,
          dataIndex: visualConfig.columnValue[columnIndex],
          key: visualConfig.columnValue[columnIndex],
        })
      );

      console.log("-=- columns");
      console.log(columns);

      const data: any[] = filterData[visualConfig.dataSource];

      console.log("-=- table data");
      console.log(data);

      return (
        <>
          <div className={styles.section}>
            <div className={styles.subtitle}>
              {visualTitle}{" "}
              <Button type="link" onClick={handleClearFilterClick}>
                Remove
              </Button>
            </div>
          </div>

          <Table columns={columns} dataSource={data} />
        </>
      );
    }
    return null;
  };

  if (isLoadingFilterData || isLoadingFunctionsData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
        }}
      >
        <Spin size="large" />
      </div>
    );
  } else if (
    errorFilterData ||
    errorFunctionsData ||
    !filterList ||
    !rawFunctionsData
  ) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
          }}
        >
          Woops. Something went wrong. We're looking into it!
        </div>
      </>
    );
  } else {
    const filterSelectList = filterList
      ? filterList
          .map(({ name, readableName }: ApplicationFilter) => ({
            value: name,
            label: readableName,
            disabled: DISABLED_ACTIONS.indexOf(name) > -1,
          }))
          .sort((a: ISelectOptions, b: ISelectOptions) =>
            ("" + a.label).localeCompare(b.label)
          )
      : [];
    return (
      <div>
        <div className={styles.section}>
          <div className={styles.title}>Filters</div>
        </div>

        <div className={styles.section}>
          <Row align="stretch" gutter={[12, 12]}>
            <Select
              showSearch
              placeholder="Select Filter"
              style={{ width: 300 }}
              onChange={handleChangeFilter}
              options={filterSelectList}
              value={selectedFilter}
            />

            {selectedFilter && renderFilterParamContent()}

            {renderFilterButton()}
          </Row>
        </div>

        {!!appliedFilter && (
          <>
            {/* <div className={styles.small_section}>
              <b>Applied Filter:</b> {filterMap[appliedFilter].readableName}
            </div> */}
            <div className={styles.section}>{renderTable()}</div>
          </>
        )}
      </div>
    );
  }
}

export default ApplicationFilters;
