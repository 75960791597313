import CryptokittiesLogo from "./cryptokitties.png";
import WolfgameLogo from "./wolfgame.avif";
import AxieInfinityLogo from "./axieinfinity.png";
import EnsLogo from "./ens.avif";

const applicationLogos: { [appId: string]: string } = {
  g42g2g3g2g32: CryptokittiesLogo,
  "dd1ec503-858e-4d76-9872-d1b5ff69edc2": WolfgameLogo,
  g87g78g7g87g78: AxieInfinityLogo,
  g8ggg87g78g7g: EnsLogo,
};

export default applicationLogos;
