import { useEffect } from "react";

import { AppstoreOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Breadcrumb } from "antd";
import idx from "idx";
import { useParams } from "react-router-dom";

import api from "../../api/_.api";
import Page, { PageName } from "../../components/Page/Page";

import { ApiApplicationDetailsResponseDTO } from "./components/ApplicationInteractions.type";
import ApplicationDetails from "./components/ApplicationDetails";
import ApplicationInteractions from "./components/ApplicationInteractions";
import ApplicationFilters from "./components/ApplicationFilters";

function Application() {
  const params = useParams();
  const applicationId = idx(params, (_) => _.applicationId) || "";

  const getInteractionsUrl = api.application.getInteractions(applicationId);

  const {
    isLoading: isLoadingApplicationData,
    error: errorApplicationData,
    data: rawApplicationData,
  } = useQuery<ApiApplicationDetailsResponseDTO, Error>({
    queryKey: ["applicationData-" + applicationId, getInteractionsUrl],
    queryFn: () => fetch(getInteractionsUrl).then((res) => res.json()),
  });
  const applicationData = idx(rawApplicationData, (_) => _.application) || null;
  const dailyData = idx(rawApplicationData, (_) => _.dailyData) || null;
  const monthlyData = idx(rawApplicationData, (_) => _.monthlyData) || null;

  const getSimilarApplicationsUrl =
    api.application.getSimilarApplications(applicationId);
  const { data: data2 } = useQuery<any, Error>({
    queryKey: [
      "applicationSimilar-" + params.applicationId,
      getSimilarApplicationsUrl,
    ],
    queryFn: () => fetch(getSimilarApplicationsUrl).then((res) => res.json()),
  });

  //hack for now (leaving this here too)
  useEffect(() => {
    (window as any).runThatFunction = api.misc.callThatFunction;
  }, []);

  useEffect(() => {
    if (data2) {
      console.log("Top 20 addresses pinged by users");
      data2.top20UsedAddresses.forEach((a: any, i: number) => {
        const tag = data2.addressTags[a];
        if (tag) {
          console.log(`${i}. ${tag} (${a?.address}) - ${a?.count} users`);
        } else {
          console.log(`${i}. ${a?.address} - ${a?.count} users`);
        }
      });
    }
  }, [data2]);

  return (
    <Page pageName={PageName.APPLICATION}>
      <Breadcrumb>
        <Breadcrumb.Item href="/applications">
          <AppstoreOutlined />
          <span>Applications</span>
        </Breadcrumb.Item>
        {applicationData && (
          <Breadcrumb.Item>{applicationData.name}</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <ApplicationDetails
        applicationData={applicationData}
        error={errorApplicationData}
        isLoading={isLoadingApplicationData}
      />

      <ApplicationFilters applicationId={applicationId} />

      <ApplicationInteractions
        dailyData={dailyData}
        monthlyData={monthlyData}
        error={errorApplicationData}
        isLoading={isLoadingApplicationData}
      />
    </Page>
  );
}

export default Application;
