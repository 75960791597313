import { apiBaseUrl, isLocal } from "../../env";

const skipAlerts =
  isLocal || new URLSearchParams(window.location.search).get("isMeshlink");

const sendMessage = async (message: any) => {
  if (skipAlerts) return;

  const apiUrl = apiBaseUrl + "poc/data/message?";
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
  return response.json();
};

export default sendMessage;
