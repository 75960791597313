import { useEffect, useRef, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import {
  Card,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Spin,
  Statistic,
} from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import api from "../../api/_.api";
import Page, { PageName } from "../../components/Page/Page";
import AddressLineItem from "../../components/AddressLineItem/AddressLineItem";
import useKeyPress from "../../hooks/useKeyPress";
import useQueryParam from "../../hooks/useQueryParam";
import formatWeiIntoEth from "../../utils/formatWeiIntoEth";
import { page, track } from "../../utils/segment";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const dateFormat = "YYYY/MM/DD";

const furthestDate = dayjs("2022/09/01").tz("UTC");
const maxDate = dayjs("2023/01/04").tz("UTC");

interface DailyStats {
  overall: {
    addressesSeen: number;
    addressesSending: number;
    addressesReceiving: number;
    transactions: number;
    gasLimit: string;
    gasPrice: string;
    value: string;
  };
  topSendersData: TopAddressesData;
  topReceiversData: TopAddressesData;
}

export interface TopAddressesData {
  topTransactionCount: { address: string; transactions: number }[];
  topValue: { address: string; value: string }[];
  topGasLimit: { address: string; gasLimit: string }[];
  topGasPrice: { address: string; gasPrice: string }[];
}

interface ApiResponseDTO {
  error?: any; // Added as hack for how errors are being handled.
  dailyStats: DailyStats;
  addressTags: { [address: string]: string };
}

function Leaderboards() {
  const [urlDate] = useQueryParam("date");
  const datePicker = useRef(null);
  const [blockchain] = useState<string>("Ethereum");
  const [selectedDate, setDate] = useState<dayjs.Dayjs>(
    dayjs(
      (urlDate ? dayjs(urlDate as string).tz("UTC") : maxDate).format(
        dateFormat
      )
    )
  );
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const pressedLeft = useKeyPress("ArrowLeft");
  const pressedRight = useKeyPress("ArrowRight");
  const [showHIWModal, setShowHIWModal] = useState(false);

  useEffect(() => {
    api.misc.sendMessage({ type: "page_visit" }).catch();
  }, []);

  useEffect(() => {
    if (pressedRight) {
      track("PRESSED_RIGHT");
      setDate((d) => {
        const nextDay = d.add(1, "day");
        if (d.startOf("day").isAfter(maxDate.startOf("day"), "day")) {
          return d.clone();
        }
        return nextDay;
      });
    } else if (pressedLeft) {
      track("PRESSED_LEFT");
      setDate((d) => {
        if (d.isSame(furthestDate, "day")) {
          return d.clone();
        }
        return d.subtract(1, "day");
      });
    }
  }, [pressedRight, pressedLeft]);

  const getLeaderboardSummary = api.leaderboard.getSummary({
    blockchain,
    month: (selectedDate.get("month") + 1).toString(),
    day: selectedDate.get("date").toString(),
    year: selectedDate.get("year").toString(),
  });

  const { isLoading, error, data } = useQuery<ApiResponseDTO, Error>({
    queryKey: ["pageData", getLeaderboardSummary],
    queryFn: () => fetch(getLeaderboardSummary).then((res) => res.json()),
  });

  const controls = (
    <>
      <Row>
        <Col span={24}>
          <b>Note:</b> We discontinued generating these daily reports. If you're
          interested in data like this, let us know!
          <br />
          <br />
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <div style={{ textAlign: "left" }}>
            <Select
              placeholder="Select a blockchain"
              defaultValue={[blockchain]}
              onClick={() => track("CLICKED_BLOCKCHAIN_SELECTOR")}
            >
              {[
                { value: "Ethereum", isDisabled: false },
                { value: "Polygon (Coming soon!)", isDisabled: true },
                { value: "Arbitrum (Coming soon!)", isDisabled: true },
                { value: "Optimism (Coming soon!)", isDisabled: true },
                { value: "Cronos (Coming soon!)", isDisabled: true },
              ].map((o, i) => {
                return (
                  <Option value={o.value} key={i} disabled={o.isDisabled}>
                    {o.value}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ textAlign: "right" }}>
            <DatePicker
              ref={datePicker}
              showToday={false}
              renderExtraFooter={() => (
                <div style={{ textAlign: "center" }}>
                  <b>Note: </b>Timezone in UTC
                </div>
              )}
              disabledDate={(current) => {
                // Can not select days before today and today
                return (
                  (current && current < furthestDate) ||
                  (current && current > maxDate.endOf("day"))
                );
              }}
              defaultValue={dayjs(selectedDate, dateFormat)}
              value={selectedDate}
              allowClear={false}
              format={dateFormat}
              onChange={(selectedDayJs) => {
                const dateFormatted = selectedDayJs?.format(dateFormat);
                track("SELECTED_DATE", { date: dateFormatted });
                dateFormatted && page(dateFormatted);

                if (selectedDayJs) setDate(selectedDayJs);
                //@ts-ignore
                if (datePicker.current?.blur) datePicker.current?.blur();
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
          }}
        >
          {isLoading ? (
            <Spin size="large" />
          ) : (
            "Woops. Something went wrong. We're looking into it!"
          )}
        </div>
      );
    } else if (error || data?.error) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
            }}
          >
            {isLoading ? (
              <Spin size="large" />
            ) : (
              "Woops. Something went wrong. We're looking into it!"
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <Row justify="space-around" align="top" gutter={[24, 24]}>
            <Col xs={24} sm={12} md={6} span={6}>
              <Card>
                <Statistic
                  title="Transactions"
                  value={data.dailyStats.overall.transactions}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6} span={6}>
              <Card>
                <Statistic
                  title="Addresses"
                  value={data.dailyStats.overall.addressesSeen}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6} span={6}>
              <Card>
                <Statistic
                  title="Senders"
                  value={data.dailyStats.overall.addressesSending}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6} span={6}>
              <Card>
                <Statistic
                  title="Receivers"
                  value={data.dailyStats.overall.addressesReceiving}
                />
              </Card>
            </Col>
          </Row>

          <br />
          <br />

          <Row justify="space-between" align="top" gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12}>
              <Card title={<>Most Active Senders by Volume</>}>
                {data.dailyStats.topSendersData.topValue.map((x, i) => {
                  return (
                    <AddressLineItem
                      key={i}
                      rank={i + 1}
                      address={x.address}
                      value={formatWeiIntoEth(x.value)}
                      addressTag={
                        data.addressTags[x.address.toLocaleLowerCase()]
                      }
                      selected={selectedAddress === x.address}
                      onHoverOver={() => setSelectedAddress(x.address)}
                      onHoverOut={() => setSelectedAddress(undefined)}
                    />
                  );
                })}
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card title={<>Most Active Receivers by Volume</>}>
                {data.dailyStats.topReceiversData.topValue.map((x, i) => {
                  return (
                    <AddressLineItem
                      key={i}
                      rank={i + 1}
                      address={x.address}
                      value={formatWeiIntoEth(x.value)}
                      addressTag={
                        data.addressTags[x.address.toLocaleLowerCase()]
                      }
                      selected={selectedAddress === x.address}
                      onHoverOver={() => setSelectedAddress(x.address)}
                      onHoverOut={() => setSelectedAddress(undefined)}
                    />
                  );
                })}
              </Card>
            </Col>
          </Row>

          <br />
          <br />

          <Row justify="space-between" align="top" gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12}>
              <Card title={<>Most Active Senders by Transactions</>}>
                {data.dailyStats.topSendersData.topTransactionCount.map(
                  (x, i) => {
                    return (
                      <AddressLineItem
                        key={i}
                        rank={i + 1}
                        address={x.address}
                        value={x.transactions}
                        addressTag={
                          data.addressTags[x.address.toLocaleLowerCase()]
                        }
                        selected={selectedAddress === x.address}
                        onHoverOver={() => setSelectedAddress(x.address)}
                        onHoverOut={() => setSelectedAddress(undefined)}
                      />
                    );
                  }
                )}
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card title={<>Most Active Receivers by Transactions</>}>
                {data.dailyStats.topReceiversData.topTransactionCount.map(
                  (x, i) => {
                    return (
                      <AddressLineItem
                        key={i}
                        rank={i + 1}
                        address={x.address}
                        value={x.transactions}
                        addressTag={
                          data.addressTags[x.address.toLocaleLowerCase()]
                        }
                        selected={selectedAddress === x.address}
                        onHoverOver={() => setSelectedAddress(x.address)}
                        onHoverOut={() => setSelectedAddress(undefined)}
                      />
                    );
                  }
                )}
              </Card>
            </Col>
          </Row>

          <div
            style={{ color: "#3498db", textAlign: "right", marginTop: 20 }}
            onClick={() => {
              track("CLICKED_HIW");
              setShowHIWModal(true);
            }}
          >
            {" "}
            How it works{" "}
          </div>

          <Modal
            title="Welcome 😊"
            open={showHIWModal}
            onCancel={() => {
              setShowHIWModal(false);
            }}
            footer={<></>}
            cancelText="Close"
          >
            <p style={{ marginTop: 10 }}>Thanks for checking us out!</p>
            <p style={{ marginTop: 10 }}>
              We're building an analytics tool that lets people understand and
              visualize what people are doing on any given blockchain. Please
              give us a try and let us know what you think!
            </p>

            <p style={{ marginTop: 10 }}>
              <b>Features</b>
              <ul>
                <li>
                  See aggregated data for any given day on a blockchain (runs
                  every ~2 hours).{" "}
                </li>
                <li>See the top senders and receivers for each day. </li>
                <li>Use the left/right arrow keys to navigate between days.</li>
                <li>
                  Hover on an address to quickly spot it in other leaderboards.
                </li>
              </ul>
            </p>

            <p style={{ marginTop: 10 }}>
              <b>Coming soon</b>
              <ul>
                <li>Subscribe to specific smart contracts. </li>
                <li>See profiles on addresses and pull similar cohorts. </li>
                <li>Historical data covering the entire blockchain. </li>
              </ul>
            </p>

            <br />
          </Modal>
        </>
      );
    }
  };

  return (
    <Page
      pageName={PageName.LEADERBOARDS}
      preTitle={controls}
      title="Daily Blockchain Stats"
    >
      {renderContent()}
    </Page>
  );
}

export default Leaderboards;
