import { useRef } from "react";

import { useVirtualizer } from "@tanstack/react-virtual";
import { Card, Dropdown, MenuProps, Tag } from "antd";

import Address from "../../../components/Address/Address";
import copyToClipboard from "../../../utils/copyToClipboard";

import styles from "../Application.module.css";

const DisabledAction = ({ text }: { text: string }) => {
  return <div style={{ cursor: "not-allowed", color: "gray" }}>{text}</div>;
};

interface Props {
  height: string;
  filters: string[];
  users: string[];
  clearSelected: () => void;
}
function GraphCard({ height, filters, users, clearSelected }: Props) {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const fakeDemoItems: MenuProps["items"] = [
    {
      key: "1",
      type: "group",
      label: "Overall",
      children: [
        {
          type: "divider",
        },
        {
          key: "Copy All",
          label: (
            <div
              onClick={() => {
                copyToClipboard(users.join("\n"));
              }}
            >
              Copy All
            </div>
          ),
        },
        {
          key: "Download",
          label: <DisabledAction text="Download CSV" />,
        },
        {
          key: "Tag",
          label: <DisabledAction text="Tag All" />,
        },
      ],
    },

    {
      type: "divider",
    },
    {
      key: "2",
      type: "group",
      label: "Application Specific",
      children: [
        {
          type: "divider",
        },
        {
          key: "Airdrop Tokens",
          label: <DisabledAction text="Airdrop Tokens" />,
        },

        {
          key: "Ban",
          label: <DisabledAction text="Ban" />,
        },
        {
          key: "Message",
          label: <DisabledAction text="Message" />,
        },
      ],
    },
  ];

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: users.length,
    getScrollElement: () => scrollableDivRef.current,
    estimateSize: () => 19,
  });

  return (
    <Card
      style={{ height }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
      }}
    >
      <div className={styles.selectedDayUsersTitle}>
        <div style={{ fontWeight: 700, fontSize: 16 }}>
          Users ({users.length})
        </div>
        <Dropdown menu={{ items: fakeDemoItems }} placement="bottom">
          <div
            style={{
              rotate: "90deg",
              fontWeight: 900,
              cursor: "pointer",
            }}
          >
            ...
          </div>
        </Dropdown>
      </div>
      <div style={{ marginBottom: 10 }}>
        {filters.map((filter) => {
          // hacky to convey the message
          return (
            <Tag
              key={filter}
              closable={filter !== "November 2022"}
              onClose={clearSelected}
            >
              {filter}
            </Tag>
          );
        })}
      </div>
      <div ref={scrollableDivRef} style={{ flex: 1, overflowY: "scroll" }}>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <div
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <Address address={users[virtualItem.index]} showClickAction />
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default GraphCard;
