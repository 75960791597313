import { ethers, BigNumber } from "ethers";

const formatWeiIntoEth = (wei: BigNumber | string): string => {
  const commifiedValue = ethers.utils.commify(ethers.utils.formatEther(wei));
  const wholeNumber = commifiedValue.split(".")[0];
  return wholeNumber + " ETH";
};

// custom function for now, can combine and generalize these later
export const formatWeiIntoEth2 = (wei: BigNumber | string): string => {
  const commifiedValue = ethers.utils.commify(ethers.utils.formatEther(wei));
  try {
    const number = Math.round(Number(commifiedValue) * 1000) / 1000;
    return number.toLocaleString();
  } catch (e) {
    // TODO check this logic later and make it parity with previous logic
    const wholeNumber = commifiedValue.split(".")[0];
    const decimal = commifiedValue.split(".")[1].split("").splice(0, 4);

    return `${wholeNumber}.${decimal.length ? decimal.join("") : "0"}`;
  }
};

export default formatWeiIntoEth;
