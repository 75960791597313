import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Address from "./pages/Address/AddressPage";
import Application from "./pages/Application/Application";
import Applications from "./pages/Applications/Applications";
import IngestionStatus from "./pages/IngestionStatus/IngestionStatus";
import Leaderboards from "./pages/Leaderboards/Leaderboards";
import NotFound from "./pages/NotFound/NotFound";
import Pulse from "./pages/Pulse/Pulse";
import UploadAbiTool from "./pages/UploadAbiTool/UploadAbiTool";

import reportWebVitals from "./reportWebVitals";
import "./index.css";

import "antd/dist/reset.css";
import "antd/dist/reset.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Leaderboards />,
    errorElement: <NotFound />,
  },
  {
    path: "/abi/upload",
    element: <UploadAbiTool />,
    errorElement: <NotFound />,
  },
  {
    path: "/address/:address",
    element: <Address />,
    errorElement: <NotFound />,
  },
  {
    path: "/applications",
    element: <Applications />,
    errorElement: <NotFound />,
  },
  {
    path: "/applications/:applicationId",
    element: <Application />,
    errorElement: <NotFound />,
  },
  {
    path: "/ingestion/status",
    element: <IngestionStatus />,
    errorElement: <NotFound />,
  },
  {
    path: "/pulse",
    element: <Pulse />,
    errorElement: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
