import { apiBaseUrl } from "../../env";

export const getAll = () => apiBaseUrl + `poc/data/applications`;

export const getInteractions = (applicationId: string) =>
  apiBaseUrl +
  `poc/data/applications/${encodeURIComponent(applicationId)}/monthly`;

export const getSimilarApplications = (applicationId: string) =>
  apiBaseUrl +
  `poc/data/applications/${encodeURIComponent(applicationId)}/similar`;
