import React, { FC } from "react";

import { Button as AntDButton, ButtonProps as AntDButtonProps } from "antd";

import ButtonName from "./ButtonName.enum";

interface Props extends AntDButtonProps, React.PropsWithChildren {
  buttonName: ButtonName;
}
const Button: FC<Props> = (props: Props) => {
  const { onClick, buttonName, ...otherProps } = props;

  const trackedClick = (event: React.MouseEvent<HTMLElement>) => {
    // TODO track click w/ segment
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <AntDButton onClick={trackedClick} {...otherProps}>
      {props.children}
    </AntDButton>
  );
};

export { ButtonName };

export default Button;
