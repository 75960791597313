import { useState } from "react";
import { Tooltip, Modal, message } from "antd";
import { CopyOutlined, SelectOutlined, EyeOutlined } from "@ant-design/icons";

import copyToClipboard from "../../utils/copyToClipboard";
import { track } from "../../utils/segment";

import Button from "../Button/Button";
import ButtonName from "../Button/ButtonName.enum";
import { Link } from "react-router-dom";

import styles from "./Address.module.css";

interface AddressLineItemProps {
  address: string;
  showAll?: boolean;
  showLast?: number;
  abi?: string;
  showClickAction?: boolean;
  tag?: string;
}
const Address = ({
  address,
  showAll,
  showLast,
  abi,
  showClickAction,
  tag,
}: AddressLineItemProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [showAbiModal, setAbiModal] = useState(false);

  const toggleAbiModal = () => {
    setAbiModal(!showAbiModal);
  };

  const copyAddress = async () => {
    track("COPIED_ADDRESS", { address });
    await copyToClipboard(address);
    messageApi.open({
      type: "success",
      content: "Address copied!",
      duration: 0.8,
    });
  };

  return (
    <section className={styles.address}>
      {contextHolder}
      <div className={styles.value}>
        {tag
          ? tag
          : showAll
          ? address
          : address.substring(0, 8) +
            "..." +
            address.substring(address.length - (showLast || 5))}
      </div>

      {showClickAction && (
        <div className={styles.action}>
          <Tooltip title="See more">
            <Link
              to={`/address/${encodeURIComponent(address)}`}
              onClick={() => {
                track("CLICKED_NAVBAR_TOOL_APPLICATIONS");
              }}
            >
              <EyeOutlined />
            </Link>
          </Tooltip>
        </div>
      )}
      <div className={styles.action} onClick={copyAddress}>
        <Tooltip title="Copy Address">
          <CopyOutlined />
        </Tooltip>
      </div>
      <div className={styles.action}>
        <Tooltip title="Open in Etherscan">
          <a
            href={`https://etherscan.io/address/${address}`}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={() => {
              track("OPENED_IN_ETHERSCAN", { address });
            }}
          >
            <SelectOutlined rotate={90} />
          </a>
        </Tooltip>
      </div>

      {abi ? (
        <div className={styles.action} onClick={toggleAbiModal}>
          ABI
        </div>
      ) : abi === null ? (
        <Tooltip title="ABI hasn't been uploaded">
          <div style={{ cursor: "not-allowed", color: "lightgray" }}>ABI</div>
        </Tooltip>
      ) : undefined}

      <Modal
        title={<div className={styles.hiwTitle}>Contract ABI</div>}
        onCancel={toggleAbiModal}
        open={showAbiModal}
        footer={[
          <Button buttonName={ButtonName.OPEN_ABI} onClick={toggleAbiModal}>
            Close
          </Button>,
        ]}
      >
        <div style={{ maxHeight: 400, overflow: "auto" }}>{abi}</div>
      </Modal>
    </section>
  );
};

export default Address;
