import useHover from "../../hooks/useHover";
import { create as createBlockie } from "../../utils/blockies";

import Address from "../Address/Address";

import styles from "./AddressLineItem.module.css";

interface AddressLineItemProps {
  rank: number;
  address: string;
  value: string | number;
  addressTag?: string;
  selected?: boolean;
  onClick?: () => void;
  onHoverOver?: () => void;
  onHoverOut?: () => void;
}
const AddressLineItem = ({
  rank,
  address,
  value,
  addressTag,
  selected,
  onClick,
  onHoverOver,
  onHoverOut,
}: AddressLineItemProps) => {
  const [hoverRef] = useHover<HTMLDivElement>({
    onHoverOver,
    onHoverOut,
  });

  return (
    <div onClick={onClick} ref={hoverRef}>
      <div
        className={`${styles.addressLineItem} ${
          selected ? styles.highlighted : ""
        }`}
      >
        <div className={styles.rank}>{rank}</div>
        <img src={createBlockie({ seed: address }).toDataURL()} alt="avatar" />
        <div className={styles.addressDetails}>
          <section className={styles.tag}>
            {addressTag || `Unknown Address`}
          </section>
          <Address address={address} />
        </div>
        <div className={styles.rankValue}>{value}</div>
      </div>
      <div className={styles.rankValueSm}>{value}</div>
    </div>
  );
};

export default AddressLineItem;
