import React, { FC, useState, useEffect } from "react";

import { Divider, Input, Layout, LayoutProps, Modal, message } from "antd";

import api from "../../api/_.api";
import { identify, track } from "../../utils/segment";

import Navbar from "./Navbar";
import styles from "./Page.module.css";
import PageName from "./PageName.enum";
import usePage from "./usePage";

const { Content, Footer } = Layout;

interface Props extends LayoutProps, React.PropsWithChildren {
  pageName: PageName;
  title?: string;
  preTitle?: React.ReactNode;
}
const Page: FC<Props> = ({
  pageName,
  preTitle,
  title,
  children,
  ...otherProps
}: Props) => {
  usePage(pageName);
  const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    api.misc.sendMessage({ type: `page_visit_${pageName}` }).catch();
  }, [pageName]);

  const submitEmail = async () => {
    // make segment call
    identify(email);
    track("SUBMITTED_EMAIL");

    // hit API
    try {
      await api.misc.sendMessage({ type: "email", email });
      messageApi.success("Success");
    } catch (e) {
      // TODO sentry
    }

    setShowSubscribeModal(false);
  };

  const submitFeedback = async () => {
    // make segment call
    if (email) identify(email);
    track("SUBMITTED_FEEDBACK");

    // hit API
    try {
      await api.misc.sendMessage({ type: "feedback", feedback, email });
      messageApi.success("Thank you!");
    } catch (e) {
      // TODO sentry
    }

    setShowFeedbackModal(false);
  };

  return (
    <Layout {...otherProps} style={{ minHeight: "100vh" }}>
      {contextHolder}
      <Navbar />
      <Content className={styles.content}>
        {preTitle}
        {title ? (
          <Divider orientation="center">
            <span className={styles.title}>{title}</span>
          </Divider>
        ) : null}
        {children}
      </Content>
      <Footer className={styles.footer}>
        <div style={{ marginBottom: 10 }}>
          Have any feedback? Want to get in touch with us?{" "}
          <span
            className={styles.footerLink}
            onClick={() => {
              track("CLICKED_FEEDBACK_FROM_FOOTER");
              setShowFeedbackModal(true);
            }}
          >
            Click here
          </span>
          .
        </div>
        <div>© 2022 Meshlink, Inc.</div>
      </Footer>

      <Modal
        centered={true}
        title={<div className={styles.modalTitle}>Subscribe</div>}
        open={showSubscribeModal}
        onCancel={() => {
          setShowSubscribeModal(false);
        }}
        onOk={submitEmail}
        cancelText="Close"
        okText="Submit"
        maskClosable={false}
      >
        <p style={{ marginTop: 10 }}>
          We promise not to spam you or share your info. We will only reach out
          when we release new features or for feedback.
        </p>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          placeholder="Email Address"
          size="large"
          onPressEnter={submitEmail}
        />
        <br />
        <br />
      </Modal>

      <Modal
        centered={true}
        title={<div className={styles.modalTitle}>Feedback</div>}
        open={showFeedbackModal}
        onCancel={() => {
          setShowFeedbackModal(false);
        }}
        onOk={submitFeedback}
        cancelText="Close"
        okText="Subscribe"
        maskClosable={false}
      >
        <p style={{ marginTop: 10 }}>
          We would love to hear from you! If you have any feature requests,
          suggestions, thoughts or gripes - please let us know!
        </p>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          placeholder="Email Address (optional)"
          size="large"
        />
        <br />
        <br />
        <Input.TextArea
          onChange={(e) => setFeedback(e.target.value)}
          value={feedback}
          placeholder="Feature requests, suggestions, questions."
          size="large"
          onPressEnter={submitFeedback}
        />
        <br />
        <br />
      </Modal>
    </Layout>
  );
};

export { PageName };
export default Page;
