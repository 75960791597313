import { useEffect } from "react";

import { page } from "../../utils/segment";
import PageName from "./PageName.enum";

export default function usePage(pageName: PageName) {
  useEffect(() => {
    page(pageName);
    // TODO track time on page through segment
  }, [pageName]);
}
