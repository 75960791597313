const analytics: any = (window as any).analytics;

const skipAlerts = new URLSearchParams(window.location.search).get(
  "isMeshlink"
);

export const identify = (id: string) => {
  if (skipAlerts) return;
  try {
    (window as any).analytics?.identify(id, {
      name: id,
      id,
    });
  } catch (e) {
    // mute
  }
};

export const track = (event: string, meta?: any) => {
  if (skipAlerts) return;
  try {
    (window as any).analytics?.track(event, meta);
  } catch (e) {
    // mute
  }
};

export const page = (page: string) => {
  if (skipAlerts) return;
  try {
    (window as any).analytics?.page(page);
  } catch (e) {
    // mute
  }
};

export default analytics;
