import * as address from "./routes/address.route";
import * as application from "./routes/application.route";
import * as applicationFilters from "./routes/applicationFilters.route";
import * as applicationFunctions from "./routes/applicationFunctions.route";
import * as leaderboard from "./routes/leaderboard.route";
import * as pulse from "./routes/pulse.route";
import callThatFunction from "./routes/callThatFunction.route";
import sendMessage from "./routes/sendMessage.route";

const api = {
  address,
  application,
  applicationFilters,
  applicationFunctions,
  leaderboard,
  pulse,

  misc: {
    callThatFunction,
    sendMessage,
  },
};

export default api;
