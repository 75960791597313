import React, { useState } from "react";

import { Card } from "antd";
import { Chart, AxisOptions, Datum } from "react-charts";

import styles from "../Application.module.css";

import { DailyStat } from "./ApplicationInteractions.type";

interface Props {
  dailyData: DailyStat[];
  onClickDatum: (datum: Datum<DailyStat> | null) => void;
}
function GraphCard({ dailyData, onClickDatum }: Props) {
  const [selectedDataType, setDataType] = useState<"users" | "transactions">(
    "users"
  );

  type Series = {
    label: string;
    data: DailyStat[];
  };

  const formattedData: Series[] = [
    {
      label: selectedDataType === "transactions" ? "Transactions" : "Users",
      data: dailyData,
    },
  ];

  const primaryAxis = React.useMemo(
    (): AxisOptions<DailyStat> => ({
      getValue: (datum) => datum.date,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<DailyStat>[] => [
      {
        getValue: (datum) =>
          selectedDataType === "transactions"
            ? datum.transactionCount
            : datum.userCount,
        elementType: "line",
      },
    ],
    [selectedDataType]
  );

  return (
    <Card>
      <div className={styles.pills}>
        <div
          className={selectedDataType === "users" ? styles.selected : ""}
          onClick={() => setDataType("users")}
        >
          Users
        </div>
        <div
          className={selectedDataType === "transactions" ? styles.selected : ""}
          onClick={() => setDataType("transactions")}
        >
          Transactions
        </div>
      </div>
      <div style={{ width: "100%", height: 400, paddingBottom: 100 }}>
        <Chart
          options={{
            data: formattedData,
            primaryAxis,
            secondaryAxes,
            tooltip: true,
            onClickDatum: onClickDatum,
          }}
        />
      </div>
    </Card>
  );
}

export default GraphCard;
