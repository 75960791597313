import { useRef } from "react";
import { Card, Dropdown, MenuProps, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "./Address.module.css";
import Address from "../../components/Address/Address";
import { useVirtualizer } from "@tanstack/react-virtual";
import copyToClipboard from "../../utils/copyToClipboard";
import { ApiResponseDTO } from "./Address.types";

const DisabledAction = ({ text }: { text: string }) => {
  return <div style={{ cursor: "not-allowed", color: "gray" }}>{text}</div>;
};

interface Props {
  height: string;
  filters: string[];
  data: ApiResponseDTO;
}
function GraphCard({ height, filters, data }: Props) {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const fakeDemoItems: MenuProps["items"] = [
    {
      key: "Tag",
      label: <DisabledAction text="Tag" />,
    },
    {
      key: "Filter",
      label: <DisabledAction text="Filter" />,
    },
    {
      key: "Airdrop Tokens",
      label: <DisabledAction text="Airdrop Tokens" />,
    },
    {
      key: "Message",
      label: <DisabledAction text="Message" />,
    },
    {
      key: "Ban",
      label: <DisabledAction text="Ban" />,
    },
    {
      key: "Download",
      label: <DisabledAction text="Download" />,
    },
    {
      key: "Copy All",
      label: (
        <div
          onClick={() => {
            copyToClipboard(data.transactions.join("\n"));
          }}
        >
          Copy All
        </div>
      ),
    },
  ];

  const sortedInteractions = Object.keys(data.interactions).sort((a, b) => {
    return data.interactions[b].length - data.interactions[a].length;
  });

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: sortedInteractions.length,
    getScrollElement: () => scrollableDivRef.current,
    estimateSize: () => 19,
  });

  return (
    <Card
      style={{ height }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
      }}
    >
      <div className={styles.selectedDayUsersTitle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 700,
            fontSize: 16,
          }}
        >
          <span style={{ marginRight: 5, whiteSpace: "nowrap" }}>
            Interactions
          </span>
          <Popover
            trigger="hover"
            placement="right"
            content={
              <div style={{ maxWidth: 400, padding: 15 }}>
                An "interaction" is defined by sending a transaction to the
                other address.
              </div>
            }
          >
            <InfoCircleOutlined
              className={styles.infoIcon}
              style={{}}
              color="red"
            />
          </Popover>
        </div>
        <Dropdown menu={{ items: fakeDemoItems }} placement="bottom">
          <div
            style={{
              rotate: "90deg",
              fontWeight: 900,
              cursor: "pointer",
            }}
          >
            ...
          </div>
        </Dropdown>
      </div>
      <div ref={scrollableDivRef} style={{ flex: 1, overflowY: "scroll" }}>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <div
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <div className={styles.addressLineItem}>
                <Address
                  address={sortedInteractions[virtualItem.index]}
                  tag={data.addressTags[sortedInteractions[virtualItem.index]]}
                />
                <div>
                  {
                    data.interactions[sortedInteractions[virtualItem.index]]
                      .length
                  }{" "}
                  {`time${
                    data.interactions[sortedInteractions[virtualItem.index]]
                      .length === 1
                      ? ""
                      : "s"
                  }`}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default GraphCard;
