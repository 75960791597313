import { Card, Col, Row, Spin } from "antd";

import applicationLogos from "../../../assets/dappLogos";
import ApplicationAddressCollapse from "../../../components/ApplicationAddressCollapse/ApplicationAddressCollapse";
import Application from "../../../types/Application.type";
import { create as createBlockie } from "../../../utils/blockies";

import styles from "../Application.module.css";

interface IApplicationDetails {
  applicationData: Application | null;
  error: Error | null;
  isLoading: boolean;
}
function ApplicationDetails({
  applicationData,
  error,
  isLoading,
}: IApplicationDetails) {
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
        }}
      >
        <Spin size="large" />
      </div>
    );
  } else if (error || !applicationData) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
          }}
        >
          Woops. Something went wrong. We're looking into it!
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.section}>
        <Row justify="space-around" align="top" gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} span={24}>
            <Card className={styles.applicationCard}>
              <img
                src={
                  applicationLogos[applicationData.id] ||
                  createBlockie({
                    seed: applicationData.id,
                  }).toDataURL()
                }
                alt="dapp-logo"
              />
              <div style={{ flex: 1 }}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>{applicationData.name}</div>
                </div>
                {applicationData.description ? (
                  <div className={styles.description}>
                    {applicationData.description}
                  </div>
                ) : undefined}
                <ApplicationAddressCollapse
                  addresses={applicationData.addresses}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ApplicationDetails;
