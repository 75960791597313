import { useState } from "react";

import { Col, Row, Spin } from "antd";
import dayjs from "dayjs";

import styles from "../Application.module.css";

import { DailyStat, MonthlyData } from "./ApplicationInteractions.type";
import GraphCard from "./GraphCard";
import UsersCard from "./UsersCard";

const showBothColumns = true;

interface IApplicationUserInteractions {
  dailyData: DailyStat[] | null;
  monthlyData: MonthlyData | null;
  error: Error | null;
  isLoading: boolean;
}
function ApplicationUserInteractions({
  dailyData,
  monthlyData,
  error,
  isLoading,
}: IApplicationUserInteractions) {
  const [selectedDay, setDay] = useState<string>();

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
        }}
      >
        <Spin size="large" />
      </div>
    );
  } else if (error || !dailyData || !monthlyData) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
          }}
        >
          Woops. Something went wrong. We're looking into it!
        </div>
      </>
    );
  } else {
    const graphCard = (
      <GraphCard
        dailyData={dailyData || []}
        onClickDatum={(datum) => {
          if (datum?.primaryValue) {
            setDay(datum.primaryValue);
          }
        }}
      />
    );

    const specificDayData = dailyData.find((d) => d.date === selectedDay);
    const allUsers = Object.keys(monthlyData.userInteractions);

    const pseudoAppliedFilters: string[] = [];

    if (selectedDay) {
      pseudoAppliedFilters.push(
        `${dayjs(selectedDay).format("MMMM D, YYYY")} (UTC)`
      );
    } else {
      pseudoAppliedFilters.push(`November 2022`);
    }

    return (
      <div className={styles.section}>
        <div className={styles.title}>Application Interactions</div>
        <div className={styles.subtitle}>November 2022</div>
        <Row align="stretch" gutter={[12, 12]}>
          {selectedDay || showBothColumns ? (
            <>
              <Col xs={24} sm={16} md={16} span={16}>
                {graphCard}
              </Col>
              <Col xs={24} sm={8} md={8} span={8}>
                <UsersCard
                  height="485.7px" // hack for now, should automate somehow to equal left col
                  filters={pseudoAppliedFilters}
                  users={
                    specificDayData ? specificDayData.userAddresses : allUsers
                  }
                  clearSelected={() => setDay(undefined)}
                />
              </Col>
            </>
          ) : (
            <Col xs={24} sm={24} md={24} span={24}>
              {graphCard}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default ApplicationUserInteractions;
