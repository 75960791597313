import { useState } from "react";

import { RightOutlined, DownOutlined } from "@ant-design/icons";

import Address from "../Address/Address";
import styles from "./ApplicationAddressCollapse.module.css";

interface Props {
  addresses: { hash: string; abi?: any }[];
}
function ApplicationAddressCollapse({ addresses }: Props) {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!isOpen);
  };

  const Cursor = isOpen ? DownOutlined : RightOutlined;

  return (
    <>
      <div className={styles.applicationAddressCollapse} onClick={toggle}>
        <Cursor className={styles.cursor} />
        <div> Addresses ({addresses.length}) </div>
      </div>
      {isOpen && (
        <div style={{ paddingLeft: 10 }}>
          {addresses.map((a) => {
            return (
              <Address
                key={a.hash}
                address={a.hash}
                showLast={8}
                abi={a.abi || null}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default ApplicationAddressCollapse;
