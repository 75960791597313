import { useState } from "react";

const useQueryParam = (queryParam: string) => {
  const [value, setValue] = useState(
    new URLSearchParams(window.location.search).get(queryParam)
  );

  const setQueryParam = (newValue: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(queryParam, encodeURIComponent(newValue));
    window.location.search = searchParams.toString();
    setValue(newValue);
  };

  return [value, setQueryParam];
};

export default useQueryParam;
