import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { AppstoreOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Col, Popover, Row, Spin, Statistic } from "antd";
import idx from "idx";
import { useParams } from "react-router-dom";

import api from "../../api/_.api";
import Page, { PageName } from "../../components/Page/Page";
import { formatWeiIntoEth2 } from "../../utils/formatWeiIntoEth";

import UsersCard from "./_UsersCard";
import GraphCard from "./_GraphCard";
import styles from "./Address.module.css";
import { ApiResponseDTO } from "./Address.types";

const StatisticWitihTooltip = ({
  title,
  value,
  tooltipContent,
}: {
  title: string;
  value: string | number;
  tooltipContent: React.ReactNode;
}) => {
  return (
    <Card>
      <Statistic
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 5, whiteSpace: "nowrap" }}>
              {title}
            </span>
            <Popover
              trigger="hover"
              placement="right"
              content={
                <div style={{ maxWidth: 400, padding: 15 }}>
                  {tooltipContent}
                </div>
              }
            >
              <InfoCircleOutlined style={{ marginBottom: -1 }} />
            </Popover>
          </div>
        }
        value={value}
        valueStyle={{
          fontSize: 18,
        }}
      />
    </Card>
  );
};

function AddressPage() {
  const params = useParams();
  const addressId = idx(params, (_) => _.address) || "";

  const getAddressesUrl = api.address.getAddressesUrl(addressId);
  const { isLoading, error, data } = useQuery<ApiResponseDTO, Error>({
    queryKey: ["addressData-" + params.address, getAddressesUrl],
    queryFn: () => fetch(getAddressesUrl).then((res) => res.json()),
  });

  console.log({ data });

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
          }}
        >
          {isLoading ? (
            <Spin size="large" />
          ) : (
            "Woops. Something went wrong. We're looking into it!"
          )}
        </div>
      );
    } else if (error || data?.error) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
            }}
          >
            {isLoading ? (
              <Spin size="large" />
            ) : (
              "Woops. Something went wrong. We're looking into it!"
            )}
          </div>
        </>
      );
    } else {
      const pseudoAppliedFilters: string[] = [];

      return (
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <AppstoreOutlined />
              <span>Addresses</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{params.address}</Breadcrumb.Item>
          </Breadcrumb>

          <br />

          <div className={styles.period}>
            <span style={{ fontWeight: "600" }}>Period:</span> 2022/11/01 -
            2022/11/30
          </div>

          <Row justify="space-between" align="top" gutter={[24, 24]}>
            <Col xs={24} sm={12} md={6} span={6}>
              <StatisticWitihTooltip
                title="Type"
                value={data.accountType}
                tooltipContent={
                  <>
                    <div>The address type.</div>
                    <br />
                    <ul>
                      <li>
                        <b>Externally owned account (EOA)</b>: Addresses
                        controlled by a private key. We consider these users or
                        wallets.
                      </li>
                      <li>
                        <b>Contract account</b>: Addresses that have contract
                        code that can be triggered by receiving a transaction.
                      </li>
                    </ul>
                  </>
                }
              />
            </Col>
            <Col xs={24} sm={12} md={6} span={6}>
              <StatisticWitihTooltip
                title="Transactions"
                value={`${data.transactionsSent} / ${data.transactionsReceived} / ${data.transactionCount}`}
                tooltipContent={
                  <>
                    <div style={{ fontWeight: 500 }}>
                      Sent / Received / Total
                    </div>
                    <br />
                    <div>Number of transactions in this period.</div>
                  </>
                }
              />
            </Col>
            <Col xs={24} sm={12} md={6} span={6}>
              <StatisticWitihTooltip
                title="Value Moved"
                value={`${formatWeiIntoEth2(
                  data.valueSent
                )} / ${formatWeiIntoEth2(data.valueReceived)}`}
                tooltipContent={
                  <>
                    <div style={{ fontWeight: 500 }}>Sent / Received</div>
                    <br />
                    <div>Amount of ETH moved in this period.</div>
                  </>
                }
              />
            </Col>
            <Col xs={24} sm={12} md={6} span={6}>
              <StatisticWitihTooltip
                title="Balance (ETH)"
                value={formatWeiIntoEth2(data.balance)}
                tooltipContent={
                  <>
                    <div>The current balance of the address.</div>
                  </>
                }
              />
            </Col>
          </Row>

          <br />
          <br />

          <Row align="stretch" gutter={[12, 12]}>
            <>
              <Col xs={24} sm={16} md={16} span={16}>
                <GraphCard
                  data={data.txnsGroupedByDay}
                  onClickDatum={(datum) => {
                    console.log(datum?.primaryValue);
                  }}
                />
              </Col>
              <Col xs={24} sm={8} md={8} span={8}>
                <UsersCard
                  height="485.7px" // hack for now, should automate somehow to equal left col
                  filters={pseudoAppliedFilters}
                  data={data}
                />
              </Col>
            </>
          </Row>
        </>
      );
    }
  };

  return <Page pageName={PageName.ADDRESS}>{renderContent()}</Page>;
}

export default AddressPage;
