import { useEffect } from "react";

import { SelectOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Spin, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

import api from "../../api/_.api";
import applicationLogos from "../../assets/dappLogos";
import ApplicationAddressCollapse from "../../components/ApplicationAddressCollapse/ApplicationAddressCollapse";
import Page, { PageName } from "../../components/Page/Page";
import Application from "../../types/Application.type";
import { create as createBlockie } from "../../utils/blockies";

import styles from "./Applications.module.css";

interface ApiResponseDTO {
  error?: any; // Added as hack for how errors are being handled.
  applications: Application[];
}

function Applications() {
  const navigate = useNavigate();

  //hack for now
  useEffect(() => {
    (window as any).runThatFunction = api.misc.callThatFunction;
  }, []);

  const getAllApplications = api.application.getAll();
  const { isLoading, error, data } = useQuery<ApiResponseDTO, Error>({
    queryKey: ["pageData", getAllApplications],
    queryFn: () => fetch(getAllApplications).then((res) => res.json()),
  });

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
          }}
        >
          {isLoading ? (
            <Spin size="large" />
          ) : (
            "Woops. Something went wrong. We're looking into it!"
          )}
        </div>
      );
    } else if (error || data?.error) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 128px)", // substracts height of nav + footer
            }}
          >
            {isLoading ? (
              <Spin size="large" />
            ) : (
              "Woops. Something went wrong. We're looking into it!"
            )}
          </div>
        </>
      );
    } else {
      return (
        <Row justify="space-around" align="top" gutter={[24, 24]}>
          {data.applications.map((a) => {
            return (
              <Col xs={24} sm={24} md={12} span={12} key={a.id}>
                <Card className={styles.applicationCard}>
                  <img
                    src={
                      applicationLogos[a.id] ||
                      createBlockie({ seed: a.id }).toDataURL()
                    }
                    alt="dapp-logo"
                  />
                  <div style={{ flex: 1 }}>
                    <div
                      className={styles.titleContainer}
                      onClick={() => {
                        navigate(`/applications/${a.id}`);
                      }}
                    >
                      <div className={styles.title}>{a.name}</div>
                      <SelectOutlined rotate={90} className={styles.open} />
                    </div>
                    {a.description ? (
                      <div className={styles.description}>{a.description}</div>
                    ) : undefined}
                    <ApplicationAddressCollapse addresses={a.addresses} />
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  return (
    <Page pageName={PageName.APPLICATIONS} title="Applications">
      {renderContent()}
    </Page>
  );
}

export default Applications;
