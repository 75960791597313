import { Layout, Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";

import DiscordSvg from "../../assets/icon_clyde_blurple_RGB.svg";
import { track } from "../../utils/segment";

import styles from "./Navbar.module.css";
import Logo from "./logo.png";

const { Header } = Layout;

const search = window.location.search;
const isMeshlink = new URLSearchParams(search).get("isMeshlink") || true;

const Navbar = () => {
  const items: MenuProps["items"] = [
    ...(isMeshlink
      ? [
          {
            key: "applications",
            label: (
              <Link
                to={"/applications" + search}
                onClick={() => {
                  track("CLICKED_NAVBAR_TOOL_APPLICATIONS");
                }}
              >
                Applications
              </Link>
            ),
          },
        ]
      : []),
    {
      key: "leaderboards",
      label: (
        <Link
          to={"/" + search}
          onClick={() => {
            track("CLICKED_NAVBAR_TOOL_LEADERBOARDS");
          }}
        >
          Leaderboards
        </Link>
      ),
    },
    ...(isMeshlink
      ? [
          {
            key: "pulse",
            label: (
              <Link
                to={"/pulse" + search}
                onClick={() => {
                  track("CLICKED_NAVBAR_TOOL_PULSE");
                }}
              >
                Pulse
              </Link>
            ),
          },
        ]
      : []),
  ];
  return (
    <Header className={styles.header}>
      <Link
        to="/"
        className={styles.logo}
        onClick={() => {
          track("CLICKED_LOGO");
        }}
      >
        <img src={Logo} style={{ maxWidth: 160 }} alt="logo" />
      </Link>

      <div className={styles.headerActions}>
        <Dropdown menu={{ items }} placement="bottom">
          <div>Tools</div>
        </Dropdown>
        <a
          href="https://discord.gg/meshlink"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            track("CLICKED_DISCORD");
          }}
        >
          <img
            className={styles.discordLogo}
            src={DiscordSvg}
            alt={"discord logo"}
          />
          <div className={styles.discordText}>Join our discord!</div>
        </a>
      </div>
    </Header>
  );
};

export default Navbar;
