import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import * as d3 from "d3";
import dayjs from "dayjs";

import api from "../../api/_.api";
import Page, { PageName } from "../../components/Page/Page";

import DirectedGraph from "./graph/directedGraph";
import { addressStringReduction, numberWithCommas } from "./utils/format";
import styles from "./Pulse.module.css";

function Pulse() {
  const getPulseData = api.pulse.getData();

  const { isLoading, data } = useQuery<any, Error>({
    queryKey: ["pageData", getPulseData],
    queryFn: () => fetch(getPulseData).then((res) => res.json()),
  });

  const blockchainPayload = data;

  useEffect(() => {
    if (blockchainPayload && Object.keys(blockchainPayload).length > 0) {
      console.log("Diagram Data: ");
      console.log(blockchainPayload);

      const chart = DirectedGraph({
        data: {
          nodes: blockchainPayload?.addressLog?.visual,
          links: blockchainPayload?.linkageLog?.visual,
        },
        nodeId: (d: any) => d?.id,
        nodeGroup: (d: any) => d?.group,
        nodeTitle: (d: any) => `${d?.id} (${d?.group})`,
        zoom: 5,
        invalidation: null, // a promise to stop the simulation when the cell is re-run
      });

      d3.select(document.getElementById("CHART_HOLDER")).append(() => chart);
    }
  }, [blockchainPayload]);

  return (
    <Page pageName={PageName.PULSE} title="Pulse">
      {isLoading ? (
        "Loading..."
      ) : (
        <div>
          {!!blockchainPayload && !!blockchainPayload.currentBlock && (
            <div className={styles.informationSection}>
              <div className={styles.informationSectionTitle}>
                {dayjs(blockchainPayload.currentBlock.timestamp * 1000).format(
                  "MMM Do [at] h:mm a"
                )}
              </div>
              <div className={styles.informationSectionSubtitle}>
                Pulse Range:
              </div>
              <div>
                Starting at block{" "}
                <a
                  href={`https://etherscan.io/block/${blockchainPayload.startingBlock}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {numberWithCommas(blockchainPayload.startingBlock)}
                </a>
              </div>
              <div>
                Ending at block{" "}
                <a
                  href={`https://etherscan.io/block/${blockchainPayload.endingBlock}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {numberWithCommas(blockchainPayload.endingBlock)}
                </a>
              </div>
              <div className="InformationSection-subtitle">Pulse Stats:</div>
              <div>
                {numberWithCommas(blockchainPayload.addressLog.visual.length)}{" "}
                unique addresses
              </div>
              <div>
                {numberWithCommas(blockchainPayload.linkageLog.visual.length)}{" "}
                transactions
              </div>

              <div className="InformationSection-subtitle">
                Interesting Details:
              </div>
              <div>
                <a
                  href={`https://etherscan.io/address/${blockchainPayload.addressWithMost}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {addressStringReduction(blockchainPayload.addressWithMost)}
                </a>{" "}
                had the most transactions{" "}
                {numberWithCommas(blockchainPayload.linkCount)}
              </div>
            </div>
          )}
          <div id="CHART_HOLDER"></div>
        </div>
      )}
    </Page>
  );
}

export default Pulse;
