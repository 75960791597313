// never delete from here, just add new ones.
enum PageName {
  HOME_PAGE = "HOME_PAGE", // DEPRECATED
  ADDRESS = "ADDRESS",
  APPLICATION = "APPLICATION",
  APPLICATIONS = "APPLICATIONS",
  LEADERBOARDS = "LEADERBOARDS",
  INGESTION_STATUS = "INGESTION_STATUS",
  UPLOAD_ABI_TOOL = "UPLOAD_ABI_TOOL",
  PULSE = "PULSE",
  NOT_FOUND = "NOT_FOUND",
}

export default PageName;
