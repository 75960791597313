import React, { useState } from "react";
import { Card, Switch } from "antd";
import { GroupedData } from "./Address.types";
import { Chart, AxisOptions, Datum } from "react-charts";
import styles from "./Address.module.css";

type SeriesDatum = {
  date: string;
  transactionCount: number;
};

interface Props {
  data: GroupedData;
  onClickDatum: (datum: Datum<SeriesDatum> | null) => void;
}
function GraphCard({ data, onClickDatum }: Props) {
  const [showAll, setShowAll] = useState(true);

  const series: SeriesDatum[] = Object.keys(data).map((date) => {
    return {
      date,
      transactionCount: data[date].length,
    };
  });

  type Series = {
    label: string;
    data: SeriesDatum[];
  };

  const formattedData: Series[] = [
    {
      label: "Transactions",
      data: series,
    },
  ];

  const primaryAxis = React.useMemo(
    (): AxisOptions<SeriesDatum> => ({
      getValue: (datum) => datum.date,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<SeriesDatum>[] => [
      {
        getValue: (datum) => datum.transactionCount,
        elementType: "line",
      },
    ],
    []
  );

  return (
    <Card>
      <div className={styles.graphCardTitleContainer}>
        <div style={{ fontWeight: 500, fontSize: 16, marginBottom: 10 }}>
          Transactions
        </div>
        <Switch
          disabled
          className={styles.toggle}
          checked={showAll}
          checkedChildren="All"
          unCheckedChildren="All"
          onChange={() => {
            setShowAll(!showAll);
          }}
        />
      </div>
      <div style={{ width: "100%", height: 400, paddingBottom: 100 }}>
        <Chart
          options={{
            data: formattedData,
            primaryAxis,
            secondaryAxes,
            tooltip: true,
            onClickDatum: onClickDatum,
          }}
        />
      </div>
    </Card>
  );
}

export default GraphCard;
