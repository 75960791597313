import { useState } from "react";

import { InboxOutlined } from "@ant-design/icons";
import { Card, Col, Row, Upload, UploadProps, message } from "antd";
import Editor from "react-simple-code-editor";
import prismjs from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

import Page, { PageName } from "../../components/Page/Page";
import Button, { ButtonName } from "../../components/Button/Button";

import styles from "./UploadAbiTool.module.css";

// import { page, track } from "../../utils/segment";
// import { apiBaseUrl } from "../../api";

const hightlightWithLineNumbers = (input: string) =>
  prismjs
    .highlight(input, prismjs.languages.js, "js")
    .split("\n")
    .map(
      (line, i) =>
        `<span class='${styles.editorLineNumber}'>${i + 1}</span>${line}`
    )
    .join("\n");

const { Dragger } = Upload;

function UploadAbiTool() {
  const [abiCode, setAbiCode] = useState("");

  const uploadProps: UploadProps = {
    accept: ".json",
    multiple: false,
    showUploadList: {
      showRemoveIcon: false,
      showDownloadIcon: false,
    },
    customRequest: async ({ file, onError, onSuccess }) => {
      console.log("custome request called?", file);

      // TODO get URL

      const url =
        "https://meshlink-abi-user-uploads.s3.us-east-1.amazonaws.com/file-15074884594215465?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5JVMJVEFJDZMGTOK%2F20221222%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20221222T214209Z&X-Amz-Expires=3600&X-Amz-Signature=1eb6875c4f2ef0d7a390c6554351d3f25543d89ea9e3a5bec8ad7b343938cdee&X-Amz-SignedHeaders=host&x-id=PutObject";

      try {
        await fetch(url, {
          method: "PUT",
          body: file,
        });

        onSuccess && onSuccess(() => {});
      } catch (e) {
        onError && onError(e as Error);
      }
    },
    onChange(info) {
      console.log({ info });
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Page pageName={PageName.UPLOAD_ABI_TOOL} title="Upload ABI">
      <Row justify="space-between" align="top" gutter={[24, 24]}>
        <Col span={24}>
          <Card>
            <Row justify="space-between" align="top" gutter={[24, 24]}>
              <Col span={11}>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Dragger>
              </Col>

              <Col span={2}>
                <div style={{ textAlign: "center" }}>or</div>
              </Col>

              <Col span={11}>
                <div className={styles.editorContainer}>
                  <Editor
                    value={abiCode}
                    onValueChange={(code) => setAbiCode(code)}
                    highlight={hightlightWithLineNumbers}
                    placeholder="copy and paste ABI here"
                    padding={10}
                    textareaId="codeArea"
                    className={styles.editor}
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 14,
                      outline: 0,
                    }}
                  />
                </div>
                <div style={{ textAlign: "right" }}>
                  <Button
                    buttonName={ButtonName.UPLOAD_ABI_TOOL_TEXT_UPLOAD}
                    disabled={abiCode.trim() === ""}
                    onClick={async () => {
                      // create a `File` object
                      const file = new File([abiCode], "file.json", {
                        type: "text/plain",
                      });

                      const url =
                        "https://meshlink-abi-user-uploads.s3.us-east-1.amazonaws.com/file-15074884594215465?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5JVMJVEFJDZMGTOK%2F20221222%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20221222T214209Z&X-Amz-Expires=3600&X-Amz-Signature=1eb6875c4f2ef0d7a390c6554351d3f25543d89ea9e3a5bec8ad7b343938cdee&X-Amz-SignedHeaders=host&x-id=PutObject";

                      try {
                        await fetch(url, {
                          method: "PUT",
                          body: file,
                        });
                        window.alert("Done");
                      } catch (e) {}
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

export default UploadAbiTool;
