import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
// import { useRef } from "react";
import Treemap from "./components/Treemap";

import Page, { PageName } from "../../components/Page/Page";

// const latestEthBlock = 16356715;
// const ethBlocksPerDay = 4000;

const dataset = {
  name: "CEO",
  children: [
    {
      name: "boss1",
      children: [
        { name: "mister_a", group: "A", value: 28, colname: "level3" },
        { name: "mister_b", group: "A", value: 19, colname: "level3" },
        { name: "mister_c", group: "C", value: 18, colname: "level3" },
        { name: "mister_d", group: "C", value: 19, colname: "level3" },
      ],
      colname: "level2",
    },
    {
      name: "boss2",
      children: [
        { name: "mister_e", group: "C", value: 14, colname: "level3" },
        { name: "mister_f", group: "A", value: 11, colname: "level3" },
        { name: "mister_g", group: "B", value: 15, colname: "level3" },
        { name: "mister_h", group: "B", value: 16, colname: "level3" },
      ],
      colname: "level2",
    },
    {
      name: "boss3",
      children: [
        { name: "mister_i", group: "B", value: 10, colname: "level3" },
        { name: "mister_j", group: "A", value: 13, colname: "level3" },
        { name: "mister_k", group: "A", value: 13, colname: "level3" },
        { name: "mister_l", group: "D", value: 25, colname: "level3" },
        { name: "mister_m", group: "D", value: 16, colname: "level3" },
        { name: "mister_n", group: "D", value: 28, colname: "level3" },
      ],
      colname: "level2",
    },
  ],
};

function IngestionStatusPage() {
  // const ref = useRef(null);

  return (
    <Page pageName={PageName.INGESTION_STATUS}>
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
          <span>Home</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item> Ingestion Status </Breadcrumb.Item>
      </Breadcrumb>

      <br></br>
      <br></br>
      <br></br>

      <div>
        <div>Ethereum</div>
        <Treemap width={600} height={400} data={dataset} />
      </div>
    </Page>
  );
}

export default IngestionStatusPage;
