import { apiBaseUrl } from "../../env";

export const callThatFunction = async (action: string) => {
  const apiUrl = apiBaseUrl + "poc/data/actions/application/process";
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ action }),
  });
  return response.json();
};

export default callThatFunction;
