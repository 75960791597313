import { useEffect } from "react";

import api from "../../api/_.api";
import Page, { PageName } from "../../components/Page/Page";

function NotFound() {
  useEffect(() => {
    api.misc.sendMessage({ type: "page_not_found_visit" }).catch();
  }, []);

  return <Page pageName={PageName.NOT_FOUND} title="Page Not Found"></Page>;
}

export default NotFound;
