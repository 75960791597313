// https://usehooks.com/useHover/
import { useState, useEffect, useRef, MutableRefObject } from "react";

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
// hook returns tuple(array) with type [any, boolean]
function useHover<T>({
  onHoverOver,
  onHoverOut,
}: {
  onHoverOver?: () => void;
  onHoverOut?: () => void;
}): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => {
    setValue(true);
    if (onHoverOver) onHoverOver();
  };
  const handleMouseOut = (): void => {
    setValue(false);
    if (onHoverOut) onHoverOut();
  };
  useEffect(() => {
    const node: any = ref.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);
      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  });
  return [ref, value];
}

export default useHover;
